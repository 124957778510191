import initialCrudState from "@/store/templates/b-crud/state";

export default () => ({
  ...initialCrudState(
    () => ({
      id: null,
      name: null,
      isActive: true,
      triggers: []
    }),
    [
      "submissionTrigger",
      "language",
      "country",
      "subject",
      "ruleTriggerQuestion",
      "question",
      "option"
    ]
  ),
  triggerGroupMatchData: undefined
});
